import * as React from 'react'
import * as Base from 'react-festival/build/infos/list-content'
import { inject } from 'mobx-react'
import { PageTicketing, DocumentLink, Price, VipContent } from '@festival/entities/page-configuration'
import { Col, Row } from 'reactstrap'
import cn from 'classnames'

export class ListContent extends Base.ListContent {

    protected get sectionTitlePrefix(): React.ReactNode {
        return <i className="mdi mdi-pound"></i>
    }

    protected getItemClassName (): string {
        return 'text-primary border-right-0 border-left-0 border-top-0 border-warning rounded-0 px-1'
    }

     protected getItemTitleTag (): 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' {
        return 'h3'
    }
}

export default inject('pageConfigurationStore', 'routeManager')(ListContent)
